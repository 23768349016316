import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/LayoutNoFooter';
import Content, { HTMLContent } from '../components/Content';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';

export const CaseStudyTemplate = ({
  content,
  contentComponent,
  client,
  featuredimage,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section">
      {helmet || ''}
      <div className="container content case-study-page">
        <div className="columns">
          <div className="column is-2">
            <p className="noMargin">{client}</p>
          </div>
          <div className="column is-10">
            <h2>{title}</h2>
          </div>
        </div>
        <div className="columns">
          <div className="column is-12">
            {featuredimage ? (
                <div className="featured-thumbnail">
                  <PreviewCompatibleImage imageInfo={{ image: featuredimage, alt: title }} />
                </div>
              ) : null}
            </div>
        </div>
        <div className="columns">
          <div className="column is-12">
          <PostContent content={content} />
          </div>
        </div>
      </div>
    </section>
  );
};

CaseStudyTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  client: PropTypes.string,
  featuredimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const CaseStudy = ({ data }) => {
  const { markdownRemark: post } = data;
  const imagePath = post.frontmatter.featuredimage && `https://ortom.ai${post.frontmatter.featuredimage.childImageSharp.fluid.src}`;

  return (
    <Layout>
      <CaseStudyTemplate
        content={post.html}
        contentComponent={HTMLContent}
        client={post.frontmatter.client}
        featuredimage={post.frontmatter.featuredimage}
        title={post.frontmatter.title}
        helmet={
          <Helmet titleTemplate="%s | Case Study">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            {/* https://ortom.ai/ */}
            <meta property="og:title" content={`${post.frontmatter.client} | Case Study`} />
            <meta property="og:description" content={post.frontmatter.title} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta property="og:image" content={imagePath} />
            <meta property="og:url" content="https://ortom.co.uk" />
            <meta property="og:site_name" content="Ortom Ltd." />
          </Helmet>}
      />
    </Layout>
  );
};

CaseStudy.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
};

export default CaseStudy;

export const caseStudyQuery = graphql`
  query CaseStudyByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        client
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 1277, maxHeight: 718, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
